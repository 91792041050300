.collect-velo-list {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: center;
    @media screen and (max-width: 600px) {
        display: flex;
        flex-direction: column;
        justify-content: initial;
        align-items: initial;
    }
}
.main-sav,
.main-reprise {
    background-color: #f0f0f0;
    width: 100vw;
    max-width: 100vw;
}
.banner-sav {
    background-image: url(../Assets/sav.jpg);
    background-position: 50% 50%;
    background-size: cover;
    height: 360px;
    margin: -62px -15px 20px -15px;
    max-width: 100vw;
}
.banner-sav::after {
    content: "";
    background: linear-gradient(
        180deg,
        rgba(0, 0, 0, 0) 40%,
        rgba(0, 0, 0, 0.5) 100%
    );
    position: absolute;
    top: 0;
    left: 0;
    height: 375px;
    width: 100%;
    max-width: 100vw;
}
.banner-sav div {
    color: white;
    text-transform: uppercase;
    text-align: start;
    max-width: 1400px;
    margin: 0 auto;
    width: 100vw;
}
.dimension-main {
    max-width: 1400px;
    margin: 0 auto;
}

h2,
h3,
.btn {
    background-color: #1d3c34;
    padding: 10px;
    margin: 20px auto;
    text-align: center;
    border-radius: 5px;
    color: white;
    width: fit-content;
}

.align-sav {
    padding: 10px;
    margin: 20px auto;
}

.btn:hover {
    background-color: #eef386;
    color: #1d3c34;
    z-index: 10;
    cursor: pointer;
}

.btn a {
    color: white;
    text-decoration: none;
}

.mb-4 {
    margin-bottom: 40px;
}

.uploadfile {
    display: flex;
    flex-direction: column;
}
.uploadfile label,
.uploadfile input {
    width: fit-content;
    margin: auto;
}

.parcours {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}
.parcours h2 {
    background-color: transparent;
    color: black;
    font-family: "GTWalsheimPro-Black";
    text-transform: uppercase;
    font-size: 55px;
    font-weight: 800;
    text-align: start;
    width: 50%;
    margin: 0;
    line-height: 100%;
    @media screen and (max-width: 920px) {
        font-size: 36px;
    }
}
.parcours_line div {
    margin: 10px 5px;
    @media screen and (max-width: 990px) {
        margin: 10px 0px;
        font-size: 13px;
    }
    @media screen and (max-width: 550px) {
        margin: 10px 0px;
        font-size: 11px;
    }
}
.parcours div {
    display: flex;
    flex-direction: row;
}
.parcoursElement {
    padding: 10px;
    white-space: nowrap;
    font-weight: 500;
    font-size: 16px;
    line-height: 130%;
    color: #b7b7b5;
    margin-left: 0;
    border-radius: 10px;
    cursor: pointer;
    @media screen and (max-width: 550px) {
        padding: 10px 5px;
    }
}

.parcoursSelected {
    background: #eef386;
    padding: 10px;
    white-space: nowrap;
    font-weight: 500;
    font-size: 16px;
    line-height: 130%;
    margin-left: 0;
    border-radius: 10px;
    cursor: pointer;
    @media screen and (max-width: 550px) {
        padding: 10px 5px;
    }
}
.btn-next {
    background-color: #1d3c34;
    padding: 10px;
    margin: 20px auto;
    text-align: center;
    border-radius: 10px;
    color: white;
    width: fit-content;
}

.margin-30 {
    margin-top: 60px;
}

.margin-btn {
    padding-top: 60px;
}

.margin-btn-center button {
    margin: auto;
}

.my-3-block {
    margin: 20px 0 0 0;
    display: block;
    width: fit-content;
}
