.banner-vp {
    background-image: url(../Assets/Banner_Solde_2024-02.png);
    background-position: 0% 40%;
    background-size: cover;
    height: 400px;
    margin: -15px -15px 20px -15px;
    opacity: 0.75;
}
.banner-vp::after {
    content: "";
    background: linear-gradient(
        180deg,
        rgba(0, 0, 0, 0) 40%,
        rgba(0, 0, 0, 0.7) 100%
    );
    position: absolute;
    top: 60px;
    left: 0;
    height: 400px;
    width: 100%;
}
.banner-vp-title {
    background: red;
    color: white;
    text-transform: uppercase;
    text-align: start;
    max-width: 1400px;
    margin: 0 auto;
    padding: 0;
    height: 0;
    position: relative;
    top: -180px;
    left: 00px;
    z-index: 1;
}
.banner-vp-title h1 {
    color: white;
    text-align: start;
    width: 60%;
    padding: 10px;
    z-index: 1;
    font-size: 64px;
    font-family: "GTWalsheimPro-Black";
    line-height: 90%;
    @media screen and (max-width: 1100px) {
        width: 80%;
    }
    @media screen and (max-width: 800px) {
        width: 100%;
        font-size: 50px;
    }
    @media screen and (max-width: 550px) {
        width: 100%;
        font-size: 45px;
        top: 250px;
        left: 30px;
    }
}
.banner-vp-title a {
    text-decoration: none;
    color: white;
}

.list-vp li {
    margin: 20px 0;
}
.font-bold {
    font-weight: bold;
    font-family: "GTWalsheimPro-Black";
}
.parcours h2 {
    background-color: transparent;
    color: black;
    font-family: "GTWalsheimPro-Black";
    text-transform: uppercase;
    font-size: 45px;
    font-weight: 800;
    text-align: start;
    width: 70%;
    margin: 0;
    line-height: 100%;
    @media screen and (max-width: 920px) {
        font-size: 32px;
        width: 90%;
    }
}

.text-xl {
    font-size: 24px;
}

.card-vp {
    background-color: #f0f0f0;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.518);
    padding: 20px;
    margin: 20px auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: fit-content;

    @media screen and (max-width: 920px) {
        width: 100%;
    }
}

.d-flex-row-center {
    display: flex;
    justify-content: center;
    align-items: center;
}
.d-flex-row-center label {
    margin-right: 5px;
}
