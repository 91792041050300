.map-modal {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    margin: 20px auto 20px auto;
    width: fit-content;
    transition: all 1s ease-in-out;

    @media screen and (max-width: 650px) {
        flex-direction: column;
        justify-content: flex-start;
        width: 90vw;
        background-color: transparent;
        margin: 20px auto 50px auto;
    }
}

.flex-wrap {
    flex-wrap: wrap;
}
.main-maps {
    overflow: hidden;
}
::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 7px;
}
::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(0, 0, 0, 0.5);
    -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}
.search-with-modal {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;

    width: fit-content;
    height: 800px;
    @media screen and (max-width: 650px) {
        width: 180vw;
    }
}
.modal_map {
    background-color: #f0f0f0;
    opacity: 1;
    width: 45vw;
    height: 680px;
    overflow-y: scroll;
    display: flex;
    flex-direction: column;
    transition: all 1s ease-in-out;
    padding: 10px;

    @media screen and (max-width: 650px) {
        width: 48%;
        margin: 0;
        height: 96%;
        border-radius: 10px;
        border: 2px solid #1d3c34;
        overflow-y: scroll;
    }
}
.modal_map h4 {
    margin: 30px 0;
    padding: 0;
    font-size: 1.2em;
    font-family: "GTWalsheimPro-UltraBold";
    text-transform: uppercase;
    text-wrap: wrap;
    @media screen and (max-width: 650px) {
        font-size: 1em;
    }
}

.modal_body {
    background-color: #f0f0f0;
    opacity: 1;
    width: 48vw;
    height: 680px;
    z-index: 500;
    display: flex;
    flex-direction: column;
    transition: all 1s ease-in-out;
    padding: 10px;
    transform: translateX(-47vw);
    overflow-y: scroll;

    @media screen and (max-width: 650px) {
        overflow-y: scroll;
        width: 86vw;
        height: 97%;
        transform: translateX(-90vw);
        margin: 0 auto 20px auto;
        border-radius: 10px;
        border: 2px solid #1d3c34;
    }
}
.transparent {
    transform: translateX(50vw);
    transition: all 0.5s ease-in-out;
}

.modal_close {
    color: #787575;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    margin-right: 20px;
    border-radius: 0 0 0 10px;
    transition: all 0.3s ease-in-out;
}
.modal_close span {
    margin-left: 10px;
    font-size: 26px;
}

.modal_close:hover {
    /* background-color: rgb(112, 111, 111);*/
    color: black;
    transition: all 0.3s ease-in-out;
    cursor: pointer;
}

.modal_close:hover .modal_close-quit {
    color: rgb(112, 111, 111);
    background-color: white;
    transition: all 0.3s ease-in-out;
}

.categoryMap span {
    font-family: "GTWalsheimPro-Bold";
    text-transform: uppercase;
    color: black;
    font-size: 16px;
}
.img-header {
    width: fit-content;
    height: fit-content;
    z-index: 5;
    margin: 10px;
    @media screen and (max-width: 1100px) {
        margin: 10px;
    }
}
.img-header img {
    width: 100px;

    transition: all 1s ease-in-out;
    border-radius: 50%;
    @media screen and (max-width: 1100px) {
        width: 80px;
    }
}

.modal-title {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    transition: all 1s ease-in-out;

    @media screen and (max-width: 550px) {
        font-size: 0.6em;
    }
}
.modal-title h2 {
    margin: 0;
    padding: 5px;
    font-family: "GTWalsheimPro-UltraBold";
    text-transform: uppercase;
    text-wrap: wrap;
    width: fit-content;
    background-color: transparent;
    color: black;
}
.modal-title p {
    font-size: 1.6em;
    margin: 0;
}

.modal-title hr {
    margin: 10px 0;
    width: 80%;
}

.hr-modal {
    width: 50%;
    margin: 10px 0;
    padding: 0;
}
.modal-description {
    margin: 10px;
    color: #1d3c34;
    width: 90%;
    height: 120px;
    overflow-y: scroll;
    padding: 5px;
    @media screen and (max-width: 1150px) {
        height: 60px;
    }
    @media screen and (max-width: 650px) {
        height: 150px;
    }
}
.modal-scroll-content {
    height: fit-content;
}

/* column coordonnées velo */
.modal-content {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    min-height: 250px;
    height: 40%;

    @media screen and (max-width: 1100px) {
        flex-direction: column;
        min-height: auto;
        height: auto;
    }
}

.modal_content-card {
    border: 2px solid #1d3c34;
    display: flex;
    flex-direction: column;
    width: auto;
    height: 100%;
    padding: 5px 10px;
    margin: 10px auto;
    transition: all 1s ease-in-out;
    color: #1d3c34;
    border-radius: 10px;

    @media screen and (max-width: 990px) {
        margin: 5px auto;
        padding: 5px;
        width: 80%;
    }
    @media screen and (max-width: 650px) {
        margin: 5px auto;
        padding: 5px;
        width: 90%;
    }
}
.modal_content-card-h2 {
    margin: 0 auto;
    color: #1d3c34;
    background: transparent;
}
.modal_content-card p {
    margin: 0;
    font-size: 0.8em;
}
.modal_content-card h3 {
    margin: 10px 0 5px 0;
    padding: 0;
    background-color: transparent;
    color: #808e79;
    text-align: start;
    font-size: 12px;
}
.modal_content-card a {
    margin: 3px;
    color: #1d3c34;
}

.modal_content-velo {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    text-align: center;
    width: auto;
    height: 100%;
    padding: 10px;
    margin: 10px auto;
    transition: all 1s ease-in-out;
    color: #1d3c34;
    font-family: "GTWalsheimPro-Bold";
}
.modal_content-velo div {
    display: flex;
    flex-direction: column;
}
.modal_content-velo img {
    width: 150px;
    transition: all 1s ease-in-out;
    border: 2px solid white;
    margin: 5px auto;
    border-radius: 5px;
    object-fit: contain;
    @media screen and (min-width: 1440px) {
        width: 250px;
    }
    @media screen and (max-width: 790px) {
        width: 200px;
    }
    @media screen and (max-width: 550px) {
        width: 100px;
    }
}

.modal_content-velo img {
    background-color: white;
}

.mx-auto {
    margin: auto;
}

.img-velo-sku {
    text-align: center;
}
.img-velo-modal img,
.img-velo-sku img {
    width: 100px;
    transition: all 1s ease-in-out;
    border: 2px solid white;
    margin: auto;
    z-index: 19;
    border-radius: 5px;
    object-position: left top;
}

.d-row-wrap {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
}
.bg-visible-none {
    background-color: gray;
}

.btn-link-web {
    cursor: pointer;
    text-decoration: none;
}
.btn-link-web a {
    text-decoration: none;
}
.margin-btn-modal {
    margin: 0 auto 10px 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.btn-link-modal button {
    display: block;
    margin-top: 0px;
    padding: 12px 30px;
    border: none;
    color: white;
    background: #808e79;
    transition: all 0.3s ease-in-out;
    text-decoration: none;
    width: fit-content;
    letter-spacing: 0.04em;
    border-radius: 50px;
    cursor: pointer;
    font-weight: 500;
    font-size: 16px;
    font-family: GTWalsheimPro-Regular, sans-serif;
    letter-spacing: 0.05em;
    text-align: center;
}
.btn-link-modal a {
    text-decoration: none;
}

.btn-link-web button {
    display: block;
    margin-top: 0px;
    padding: 12px 30px;
    border: none;
    color: white;
    background: #808e79;
    transition: all 0.3s ease-in-out;
    text-decoration: none;
    width: fit-content;
    letter-spacing: 0.04em;
    border-radius: 50px;
    cursor: pointer;
    font-weight: 500;
    font-size: 16px;
    font-family: GTWalsheimPro-Regular, sans-serif;
    letter-spacing: 0.05em;
    text-align: center;
    @media screen and (max-width: 1100px) {
        padding: 12px;
    }
    @media screen and (max-width: 900px) {
        padding: 12px;
        font-size: 14px;
    }
}

.modal-result-search-hidden {
    height: 0;
    overflow: hidden;
}
.modal-result-search {
    padding: 0;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: center;
    height: fit-content;
}
.modal-result-search div {
    width: 30%;
    height: 150px;
    margin: 10px auto;
    padding: 5px;
    display: flex;
    color: #1d3c34;
    border: 2px solid #1d3c34;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    transition: all 0.3s ease-in-out;
    border-radius: 10px;

    @media screen and (max-width: 2000px) {
        width: 45%;
        min-height: 140px;
    }
    @media screen and (max-width: 950px) {
        width: 90%;
        min-height: 140px;
    }
    @media screen and (max-width: 650px) {
        width: 45%;
        min-height: 140px;
    }
    @media screen and (max-width: 390px) {
        width: 43%;
        min-height: 140px;
    }
}
.modal-result-search small {
    @media screen and (max-width: 650px) {
        font-size: 9px;
    }
}

.modal-result-search div h3 {
    color: #1d3c34;
    margin: -5px auto 0 auto;
    font-size: 1rem;
    text-wrap: wrap;
    overflow: hidden;
    background-color: transparent;
    font-family: "GTWalsheimPro-Black";
    text-transform: uppercase;
    width: 90%;
    max-width: 90%;
    @media screen and (max-width: 550px) {
        font-size: 0.8rem;
    }
}
.modal-result-search div img {
    margin: 0 auto 5px auto;
}

.modal-result-search div:hover {
    border: 2px solid #1d3c34;
    background-color: #cecece;
    transition: all 0.3s ease-in-out;
    cursor: pointer;
}
