.total-amount {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    text-align: end;
}

.panier {
    margin: 20px auto;
    width: 90%;
    background-color: #d9d9d6;
    padding: 20px;
    border-radius: 5px;
}
.panier div {
    margin: 10px 0;
}

.d-none {
    display: none;
}

.div-btn-panier {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}
.div-btn-panier div button {
    margin: 0 10px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.itemslines-batterie-confirm {
    display: flex;
    flex-direction: column;
    margin: 10px;
    padding: 10px;
    border-radius: 5px;
    background-color: white;
    color: black;
    transition: all ease-in-out 250ms;
}
