.centered {
    width: 50vw; /* replace with your desired width */
    margin: 0; /* this centers the div horizontally */
    height: 700px;
    z-index: 600;
    @media screen and (max-width: 650px) {
        width: 90vw;
        margin: 20px auto 20px auto;
        height: 30vh;
    }
    @media screen and (max-width: 450px) {
        width: 85vw;
        height: 300px;
    }
}
.grey-maps {
    filter: grayscale(100%);
}

.choice-icon-map {
    background-color: white !important;
    padding: 5px;
    width: fit-content;
    border-radius: 10px;
}
.leaflet-pane {
    filter: grayscale(25%);
}

.img-map-gestion {
    width: 80px;
    margin-top: 10px;
}

#table_map {
    max-width: 95vw;
    height: fit-content;
    overflow: scroll;
}

.banner-map {
    background-image: url(../Assets/velo-vue-arriere-lifestyle-comp.jpg);
    background-position: 50% 50%;
    background-size: cover;
    height: 360px;
    margin: -62px -15px 20px -15px;
}

.banner-title {
    color: white;
    text-transform: uppercase;
    text-align: start;
    max-width: 1400px;
    width: 97vw;
    margin: 0 auto;
    padding: 0;
    height: 0;
    position: relative;
    top: -190px;
    left: 00px;
    z-index: 1;
}
.banner-title h1 {
    color: white;
    text-align: start;
    width: 600px;
    padding: 10px;
    z-index: 1;
    font-size: 70px;
    font-family: "GTWalsheimPro-Black";
    line-height: 90%;
    @media screen and (max-width: 550px) {
        font-size: 30px;
        top: 250px;
        left: 30px;
        max-width: 80vw;
    }
}
.banner-title a {
    text-decoration: none;
    color: white;
}
.m-auto {
    margin: 20px auto;
}
.found-map {
    background-color: #f0f0f0;
    min-height: 520px;
    height: fit-content;
    margin: -20px;
    padding: 20px;
    @media screen and (max-width: 650px) {
        padding: 10px;
    }
}
.found-map-h2 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: fit-content;
    margin: auto;
    height: 100px;
}

.found-map-title {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
}

.found-map-title h2 {
    text-align: center;
    font-family: "Cardinal";
    font-size: 30px;
    background: transparent;
    color: initial;
    line-height: 120%;
    margin-top: 0;
    font-weight: 500;
}

.found-map-title p {
    text-align: center;
    font-family: "Cardinal";
    font-size: 30px;
    background: transparent;
    color: initial;
    line-height: 120%;
    font-weight: 500;
    width: 90%;
    margin: 20px auto 30px auto;
    @media screen and (max-width: 650px) {
        width: 90%;
    }
}

.found-map-content {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 40px;
    margin: 0 30px;

    @media screen and (max-width: 1130px) {
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: repeat(2, 1fr);
    }
    @media screen and (max-width: 650px) {
        grid-gap: 10px;
        margin: 0;
    }
}
.found-map-content-card {
    background-color: #e4e4e3;
    border-radius: 10px;
    border: 2px solid #1d3c34;
    width: auto;
    max-width: 350px;
    margin: 10px auto;
    padding: 20px;
    display: flex;
    flex-direction: column;
    height: auto;
    @media screen and (max-width: 790px) {
        padding: 10px;
    }
    @media screen and (max-width: 650px) {
        width: 30vw;
        max-width: auto;
        height: 210px;
    }
    @media screen and (max-width: 550px) {
        width: 40vw;
        max-width: auto;
        height: 220px;
    }
    @media screen and (max-width: 450px) {
        width: 40vw;
        max-width: auto;
        height: 260px;
    }
}
.found-map-content-card-content {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}
.found-map-content-card-title {
    height: 50px;
    min-height: 50px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    @media screen and (max-width: 790px) {
        height: 30px;
        min-height: 30px;
    }
    @media screen and (max-width: 650px) {
        height: 30px;
        min-height: 30px;
    }
}
.found-map-content-card-title h3 {
    margin: 0;
    padding: 0;
}
.found-map-content-card-text {
    height: 190px;
    font-size: 16px;
    @media screen and (max-width: 1440px) {
        height: 230px;
    }
    @media screen and (max-width: 1130px) {
        height: 160px;
    }
    @media screen and (max-width: 790px) {
        padding: 5px;
        font-size: 15px;
    }

    @media screen and (max-width: 650px) {
        height: 260px;
        font-size: 12px;
    }
    @media screen and (max-width: 450px) {
        height: 160px;
        font-size: 12px;
    }
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    transition: all ease-in-out 400ms;
}
.found-map-content-card-text p {
    padding: 0;
    margin: 5px 0;
}
.found-map-content-card-img {
    position: relative;
    bottom: -px;
    height: 50px;
    width: 50px;
    margin: 0 auto;
    object-fit: contain;
}

.found-map-content-card h3 {
    background-color: transparent;
    color: black;
    font-family: "GTWalsheimPro-Black";
    text-transform: uppercase;
    font-size: 21px;

    @media screen and (max-width: 790px) {
        font-size: 18px;
    }
    @media screen and (max-width: 650px) {
        font-size: 14px;
    }
    @media screen and (max-width: 500px) {
        font-size: 13px;
    }
}

.filter-categories {
    margin: 50px 30px 0 30px;
    display: flex;
    flex-direction: column;
    @media screen and (max-width: 550px) {
        margin: 50px 5px 0 5px;
    }
}

.filter-categories h2 {
    background-color: transparent;
    color: black;
    font-family: "GTWalsheimPro-Black";
    text-transform: uppercase;
    font-size: 2.3em;
    margin-left: -10px;
    margin-bottom: 50px;
    width: 100%;
}

.filter-categories h3 {
    background-color: transparent;
    color: black;
    font-family: "GTWalsheimPro-Black";
    text-transform: uppercase;
    font-size: 1.6em;
    width: 100%;
    text-align: start;
    margin: 0;
    @media screen and (max-width: 550px) {
        font-size: 1.2em;
    }
}
.filter-search {
    padding-top: 5px;
    padding-bottom: 20px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: center;
    text-align: center;
}
.filter-search div {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin: 5px;
    font-family: "GTWalsheimPro-Black";
    text-transform: uppercase;
    font-size: 12px;
    @media screen and (max-width: 900px) {
        font-size: 10px;
        width: 30vw;
        height: 40px;
    }
    @media screen and (max-width: 550px) {
        font-size: 9px;
        width: fit-content;
        height: 50px;
    }
}

.filterCat {
    background-color: #f0f0f0;
    border-radius: 10px;
    padding: 3px 5px;
    width: fit-content;
    border: 2px solid #1d3c34;
    border-radius: 10px;
    min-height: 40px;
}

.filterCat span,
.filterCatSelected span {
    padding: 1px 0;
}
.filterCat:hover {
    transition: all ease-in-out 400ms;
    background-color: #cecece;
    border: 2px solid #1d3c34;
    border-radius: 10px;
    cursor: pointer;
    @media screen and (max-width: 550px) {
        width: fit-content;
    }
}
.filterCatSelected {
    background-color: #eef386;
    border: 2px solid #1d3c34;
    border-radius: 10px;
    padding: 3px 5px;
    min-height: 40px;
    @media screen and (max-width: 550px) {
        width: fit-content;
    }
}
.filterCatSelected:hover {
    cursor: pointer;
}

.icon-cat {
    width: 30px;
    height: 30px;
    object-fit: contain;
}

.section-mascotte {
    background-color: #f0f0f0;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    padding: 20px 0;
    margin: 0;
    padding-bottom: 30px;
    width: 100%;
}
.section-mascotte p {
    margin: 0;
    font-family: "GTWalsheimPro-Black";
    text-transform: uppercase;
    font-size: 16px;
    line-height: 120%;
    padding: 0 5px;
    @media screen and (max-width: 550px) {
        font-size: 14px;
    }
}
.section-mascotte small {
    color: #757575;
    font-size: 16px;
    line-height: 121%;
    padding: 0 5px;
    text-align: center;
    margin-top: 2px;
    @media screen and (max-width: 550px) {
        font-size: 14px;
    }
}

/* FOOTER MAPS */

.footer-maps {
    background-color: #131615;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

.footer-title {
    font-family: "Cardinal";
    font-size: 100px;
    line-height: 90%;
    letter-spacing: -0.02em;
    color: white;
    margin: 30px 50px;

    transition: all ease-in-out 400ms;
    margin-left: 140px;
    @media screen and (max-width: 1500px) {
        margin-left: 80px;
    }
    @media screen and (max-width: 1100px) {
        font-size: 90px;
    }
    @media screen and (max-width: 700px) {
        font-size: 52px;
    }
    @media screen and (max-width: 550px) {
        font-size: 56px;
        margin: 30px 20px;
    }
    @media screen and (max-width: 350px) {
        font-size: 36px;
        margin: 30px 20px;
    }
}
.footer-content {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    flex-wrap: wrap;
    margin: 0px 150px;
    transition: all ease-in-out 400ms;
    @media screen and (max-width: 1500px) {
        margin: 0px 50px;
    }
    @media screen and (max-width: 970px) {
        flex-direction: column;
    }
    @media screen and (max-width: 550px) {
        margin: 10px;
    }
}

.footer-content-network {
    width: 390px;
    display: flex;
    flex-direction: column;
    margin-top: 10px;
    margin-left: 30px;
    @media screen and (max-width: 970px) {
        width: 90%;
    }
}
.footer-content-network div label {
    font-family: "GTWalsheimPro-Bold";
    text-transform: uppercase;
    color: whitesmoke;
    margin-left: 0px;
}

.footer-content-network-newsletter {
    display: flex;
    flex-direction: column;
}
.footer-content-network-newsletter input {
    margin-top: 5px;
    height: 50px;
    border-radius: 10px;
    border: none;
    padding: 2px 20px;
    color: #696969;
    font-size: 0.9em;
    line-height: 1.5rem;
    width: 80%;
    @media screen and (max-width: 970px) {
        margin: auto;
    }
}
.footer-content-network-newsletter svg {
    position: relative;
    top: -50px;
    float: right;
    margin-right: 45px;
    background-color: #808e79;
    padding: 12px;
    line-height: 120%;
    font-size: 20px;
    border-radius: 50px;
}

.footer-content-listing {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    justify-content: flex-start;
    width: calc(95% - 440px);
    padding: 10px;
    margin-left: -20px;
    @media screen and (max-width: 970px) {
        width: 100%;
    }
    @media screen and (max-width: 550px) {
        margin: 0;
        width: 100%;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: flex-start;
    }
}
.footer-content .list-link {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    text-align: start;
    margin: 10px auto;
    @media screen and (max-width: 550px) {
        margin: 10px;
        width: 40vw;
    }
}
.footer-maps .list-link h2 {
    font-family: "GTWalsheimPro-Medium";
    font-size: 18px;
    line-height: 130%;
    width: fit-content;
    margin: 8px 0 6px 0;
    padding: 0;
    background-color: inherit;
    border-radius: 0;
}

.footer-maps .list-link a {
    font-size: 16px;
    line-height: 121%;
    color: rgba(217, 217, 214, 0.86);
    margin: 8px 0px;
}

.footer-social {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 250px;
    margin: -20px 0 0 -15px;
    @media screen and (max-width: 970px) {
        display: none;
    }
}
.footer-social .logo a {
    margin: 0 18px;
    font-size: 30px;
}
.footer-social .mentions {
    font-size: 12px;
    line-height: 120%;
    margin-top: 30px;
    margin-left: 15px;
    color: #d9d9d6;
}
.footer-social .mentions a,
.footer-social-mobile .mentions a {
    color: #d9d9d6;
}

.footer-social-mobile {
    display: none;
    @media screen and (max-width: 970px) {
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 250px;
        margin: 20px auto 0 auto;
        text-align: center;
    }
}
.footer-social-mobile .logo a {
    margin: 0 10px;
    font-size: 26px;
}
.footer-social-mobile .mentions {
    font-size: 12px;
    line-height: 120%;
    margin-top: 10px;
    color: #d9d9d6;
}
.text-white {
    color: white;
}

.footer-copyright {
    text-align: center;

    margin: 5px auto 30px auto;
}

.footer-svg {
    margin: 35px auto 5px auto;
}
.footer-svg svg {
    width: 88vw;
    max-width: 1350px;
    margin: auto;
    @media screen and (max-width: 950px) {
        width: 97vw;
    }
}

.footer-copyright span {
    font-family: "GTWalsheimPro-Bold";
}

.btn-submit {
    margin: 0px 0 0 10px;
}

.visible {
    margin-right: 20px;
}

.developIcon,
.developIcon-reverse {
    margin: 0 10px;
    font-family: "GTWalsheimPro-Regular";
    padding: 5px 2px 0 2px;
    font-size: 14px;
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: fit-content;
}
.developIcon svg,
.developIcon-reverse svg {
    font-size: 26px;
    transform: rotate(180deg);
}
.developIcon-reverse svg {
    transform: rotate(0deg);
}
