.stats-slidebar {
    background-color: #808e79;
    height: calc(100%);
    width: 20px;
    position: fixed;
    left: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 4px 4px 0px #00000040;
    transition: all ease-in-out 400ms;
    padding: 10px;
    color: white;
    overflow-x: hidden;
    @media screen and (max-height: 850px) {
        height: 800px;

        justify-content: flex-end;
        align-items: flex-end;
        overflow-z: scroll;
    }
}
.sticky-top {
    position: sticky;
    top: 0px;
    z-index: 2;
}
.sticky-left {
    position: sticky;
    left: 0px;
    z-index: 2;
    background-color: whitesmoke;
}
.stats-slidebar span {
    display: none;
}
.cursor-pointer {
    cursor: pointer;
}
.cursor-zoom {
    cursor: zoom-in;
}
.hidden {
    display: none;
}
.text-paid {
    color: #86d4f3;
}
.stats-slidebar a {
    color: white;
    margin: 10px auto;
    overflow: hidden;
    width: 250px;
    padding: 2px;
    font-size: 22px;
    background-color: #808e7960;
    @media screen and (max-height: 850px) {
        margin: 0 auto;
    }
}
.stats-slidebar:hover a {
    background-color: #808e7960;
}
.stats-slidebar:hover a svg {
    margin-right: 10px;
}
.stats-slidebar a:hover {
    color: #eef386;
}
.bg-table {
    background: #1d3c34;
    color: white;
}
.link-active {
    background-color: #1d3c34;
    padding: 5px;
    padding-left: 0px;
    border-radius: 5px;
}
.stats-slidebar:hover,
.open {
    width: 350px;
    z-index: 2;
    transition: all ease-in-out 400ms;
    font-size: 18px;
}

.stats-slidebar:hover span,
.open span {
    display: inline;
    overflow: hidden;
    transition: all ease-in-out 400ms;
    font-size: 16px;
}

.text-primary {
    color: #1d3c34;
}
.dateSelected {
    background-color: #eef386;
    border-radius: 5px;
    padding: 5px;
    margin: 10px auto;
    width: fit-content;
}

.bg-red {
    background-color: red;
}

.bg-light-red {
    background-color: rgb(253, 203, 203);
}

.bg-light-green {
    background-color: rgb(212, 251, 213);
}

.commandes-velos {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.title-td {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}
.title-td svg {
    margin: 5px 10px;
    transition: all ease-in-out 400ms;
}
.title-td:hover svg {
    cursor: pointer;
    color: #eef386;
    font-size: 20px;
    transition: all ease-in-out 400ms;
}

.graphVeloRow {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: flex-start;
}

.ligne-td-0 {
    color: transparent;
}
.td-transparent {
    color: transparent;
}
#my-velos tr:nth-child(even) {
    background-color: rgb(245, 245, 245);
}
#my-velos tr:nth-child(odd) {
    background-color: rgb(245, 245, 245);
}
#my-velos tr:hover {
    background-color: #eef3865f;
    color: black;
}

#my-velos tr:hover .td-transparent {
    color: grey;
}

.div-table-reparation {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: flex-start;
    @media screen and (max-width: 1050px) {
        flex-direction: column;
    }
}
.div-table-reparation-div {
    width: fit-content;
    margin: 0 auto;
}

.graph-repart {
    margin: 30px auto;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    flex-wrap: wrap;
}

.calendar {
    transform: translateX(-400px);
    transition: all ease-in-out 800ms;
}
.stats-slidebar:hover .calendar {
    transform: translateX(0px);
    transition: all ease-in-out 800ms;
}

.table-customer {
    margin: 20px auto;
}

.calendar-customer {
    max-width: 350px;
    margin: 20px auto;
}

.table-center {
    margin: 20px auto;
}

.text-no-wrap {
    text-wrap: nowrap;
}

.bg-total {
    background-color: #727272;
    color: white;
}
.bg-retail {
    background-color: #bbe1e8;
    color: black;
}
.bg-online {
    background-color: #f3e486;
    color: black;
}
.bg-white {
    background-color: white;
}

.bg-b2b {
    background-color: #d8cef8;
    color: black;
}

.z-index-4 {
    z-index: 4;
}
