@font-face {
    font-family: "GTWalsheimPro-Regular";
    src: url("GTWalsheimPro-Regular.ttf") format("truetype");
}

@font-face {
    font-family: "GTWalsheimPro-Medium";
    src: url("GTWalsheimPro-Medium.ttf") format("truetype");
}
@font-face {
    font-family: "GTWalsheimPro-Bold";
    src: url("GTWalsheimPro-Bold.ttf") format("truetype");
}
@font-face {
    font-family: "GTWalsheimPro-Black";
    src: url("GTWalsheimPro-Black.ttf") format("truetype");
}
@font-face {
    font-family: "GTWalsheimPro-UltraBold";
    src: url("GTWalsheimPro-UltraBold.ttf") format("truetype");
}
@font-face {
    font-family: "Cardinal";
    src: url("CardinalFruitItalic.ttf") format("truetype");
}

@import "react-toastify/dist/ReactToastify.css";

* {
    -webkit-font-smoothing: antialiased;
    /* Optimiser l'affichage des fonts (surtout pour Mac) */
}
html {
    scroll-behavior: smooth;
}
body {
    margin: 0;
    padding: 0;
    font-family: "GTWalsheimPro-Regular";
}

header {
    height: 60px;
    border-bottom: 2px solid #d9d9d6;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    position: sticky;
    top: 0;
    z-index: 99999;
    background-color: white;
}

header a {
    margin: 0 9px;
    color: rgba(0, 0, 0, 0.747);
    text-decoration: none;
    font-size: 16px;
    @media screen and (max-width: 1100px) {
        margin: 0 5px;
    }
}
header a:hover {
    color: black;
    margin: 0 9px;
    margin-right: 9px;
    font-size: 16px;
    text-decoration: underline;
    text-decoration-color: #eef386;
    text-underline-offset: 5px;
    text-decoration-thickness: 3px;

    @media screen and (max-width: 1100px) {
        margin: 0 5px;
    }
}
.header-map {
    display: block;
    flex-direction: row;
    justify-content: start;
    align-items: center;
    margin-top: 20px;
}

.logo_velo {
    width: 90px;
    margin-left: 0px;
    @media screen and (max-width: 1200px) {
    }
    @media screen and (max-width: 1150px) {
        width: 70px;
    }
    @media screen and (max-width: 1090px) {
        width: 60px;
    }
    @media screen and (max-width: 1070px) {
        width: 50px;
    }
    @media screen and (max-width: 1060px) {
        width: 90px;
    }

    @media screen and (max-width: 550px) {
        width: 60px;
        margin: 10px 0 0 0;
    }
    transition: all 0.3s ease-in-out;
}
h1 {
    font-family: "GTWalsheimPro-Bold", sans-serif;
}
h1,
.text-center {
    text-align: center;
}

.mx-auto {
    margin-left: auto;
    margin-right: auto;
}

main {
    padding: 15px;
    min-height: calc(100vh - 90px);
}

.div_mascotte {
    text-align: center;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}
.div_mascotte h2 {
    margin: 0;
}
.mascotte {
    width: fit-content;

    height: 100%;
}

.text-barre {
    text-decoration: line-through;
    color: #b7b7b5;
    font-family: "GTWalsheimPro-Regular";
    font-weight: 400;
    text-transform: initial;
}
.text-red {
    color: red;
}
.text-green {
    color: rgb(8, 162, 8);
}
.text-black-price {
    color: #696969;
    font-family: "GTWalsheimPro-Black";
    text-transform: initial;
}
.d-none {
    display: none;
}

.text-center {
    text-align: center;
}

.full_video {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    object-fit: cover;
    width: 100%;
    height: 100%;
    z-index: -1;
    opacity: 0.4;
}
.main-header {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    width: 100%;
}
.main-header div {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    @media screen and (max-width: 550px) {
        font-size: 11px;
    }
}

.main-header a {
    font-size: initial;
    text-align: center;

    @media screen and (max-width: 550px) {
        font-size: 11px;
        text-align: center;
    }
}
.admin-header {
    position: sticky;
    top: 60px;
    z-index: 3;
    margin: 0;
    background-color: #1d3c34;
    color: white;
    height: 30px;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
}

.admin-header a {
    color: white;
    text-decoration: none;
    font-size: inherit;
    text-decoration-color: #1d3c34;
    text-underline-offset: 2px;
    transition: all ease-in-out 450ms;
    @media screen and (max-width: 900px) {
        font-size: 8px;
    }
}

.admin-header a:hover {
    color: #eef386;
    text-decoration: underline;
    text-decoration-color: #eef386;
    text-underline-offset: 4px;
    text-decoration-thickness: 3px;
    transition: all ease-in-out 450ms;
}

.admin-stats {
    position: sticky;
    top: 60px;
    z-index: 3;
    margin: 0;
    background-color: #121413;
    color: #eef386;
    height: 30px;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
}

.admin-stats a {
    color: white;
    font-size: initial;
    text-decoration: none;
    text-decoration-color: #eef386;
    text-underline-offset: 2px;
    transition: all ease-in-out 450ms;
    @media screen and (max-width: 900px) {
        font-size: 8px;
    }
}
.admin-stats a:hover {
    color: #eef386;
    background-color: inherit;
    font-size: initial;
    text-decoration: underline;
    text-decoration-color: #eef386;
    text-underline-offset: 2px;
    transition: all ease-in-out 450ms;
    @media screen and (max-width: 900px) {
        font-size: 8px;
    }
}
.admin-stats .link-active {
    background-color: #eef386;
    color: #131615;
    padding: 5px;
    border-radius: 10px;
    transition: all ease-in-out 450ms;
}
.admin-stats span {
    margin-left: 5px;
}
.f-icon {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}
.f-icon svg {
    font-size: 24px;
}

nav {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    width: 100%;
    max-width: 1460px;
    margin: auto;
}
.nav-desktop {
    @media screen and (max-width: 1060px) {
        display: none;
    }
}

nav div {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}
.nav-left {
    position: relative;
    left: 0px;
}
.nav-left a,
.nav-left a:hover,
.nav-right a,
.nav-right a:hover {
    margin: 10px;
    @media screen and (max-width: 1270px) {
        margin: 7px;
    }
    @media screen and (max-width: 1200px) {
        margin: 5px;
    }
    @media screen and (max-width: 1150px) {
        margin: 3px;
    }
}

.nav-right {
    position: relative;
    right: 0px;
}

.mm-btn-border-white {
    padding: 12px 24px;
    font-size: 13px;
    line-height: 120%;
    border: solid 1px #1d3c34;
    background-color: #1d3c34;
    color: white;
    transition: all 0.3s ease-in-out;
    cursor: pointer;
    text-align: center;
    letter-spacing: 0.04em;
    text-transform: uppercase;
    border-radius: 50px;
    text-wrap: nowrap;
    @media screen and (max-width: 1240px) {
        padding: 12px 6px;
    }
}
.mm-btn-border-white:hover {
    padding: 12px 6px;
    font-size: 13px;
    line-height: 120%;
    border: solid 1px #1d3c34;
    color: #1d3c34;
    background-color: white;
    transition: all 0.3s ease-in-out;
    text-decoration: none;
    text-align: center;
    letter-spacing: 0.04em;
    text-transform: uppercase;
    border-radius: 50px;
    @media screen and (min-width: 1240px) {
        padding: 12px 24px;
    }
}
.mm-header-avis-client {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    cursor: pointer;
}
.mm-header-avis-client:hover {
    text-decoration: none;
    font-family: "GTWalsheimPro-Regular";
}
.note {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-color: #f5f5f4;
    color: #1d3c34;
    font-size: 15px;
    border-radius: 20px;
    padding: 5px 10px;
}
.mm-header-avis-client-review_count {
    font-size: 15px;
    font-weight: 400;
    line-height: 120%;
    margin-left: 8px;
    @media screen and (max-width: 1240px) {
        display: none;
    }
}
/* SUBMENU */
.sub_menu {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 590px;
    position: absolute;
    top: 60px;
    left: 0;
    background: white;

    @media screen and (max-width: 1060px) {
        display: none;
    }
}
.sub_menu-acc {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 460px;
    position: absolute;
    top: 60px;
    left: 0;
    background: white;
    overflow: hidden;
    padding-bottom: 20px;

    @media screen and (max-width: 1050px) {
        height: 400px;
    }
    @media screen and (max-width: 1060px) {
        display: none;
    }
}
.sub_menu hr {
    margin: 0px auto 30px;
    color: #b7b7b5;
}
.nav-velo-desktop {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 525px;
    max-width: 1370px;
    margin: 0 auto;
}
.nav-velo-desktop-listing {
    width: 50%;
    padding: 20px;
    height: fit-content;
}
.nav-velo-desktop-listing h2 {
    font-family: "GTWalsheimPro-Black";
    font-size: 46px;
    margin: -10px 0 0 -10px;
    color: #131615;
    background-color: transparent;
    text-transform: uppercase;
    @media screen and (max-width: 1050px) {
        font-size: 30px;
    }
}
.link-comparateur {
    color: #b7b7b5;
}
.nav-velo-desktop-listing .link-comparateur,
.nav-acc-desktop-listing .link-comparateur {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: fit-content;
    margin: 20px 0 0 -10px;
}

.nav-velo-desktop-listing .link-comparateur a,
.nav-acc-desktop-listing .link-comparateur a {
    text-decoration: underline;
    font-family: "GTWalsheimPro-Medium";
}
.nav-velo-desktop-video {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: 50%;
    z-index: 2;
    margin-top: 10px;
}
.nav-velo-desktop-video a {
    text-decoration: none;
    width: 100%;
    overflow: hidden;
    height: 90%;
    max-width: 380px;
    position: relative;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
}
.nav-velo-desktop-video a:hover {
    text-decoration: none;
}
.nav-velo-desktop-video a::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(
        180deg,
        rgba(0, 0, 0, 0) 40%,
        rgba(0, 0, 0, 0.7) 100%
    );
    z-index: 3;
}
.nav-velo-desktop-video-content {
    position: relative;
    top: 0;
    left: 0;
    height: fit-content;
    z-index: 4;
    padding: 20px;
    margin: 10px;
    transform: translateY(-100%);
    color: white;
}
.nav-velo-desktop-video h2 {
    font-family: "GTWalsheimPro-Bold";
    font-size: 30px;
    line-height: 100%;
    margin: 0 0 -20px -10px;
    color: white;
    background-color: transparent;
    text-align: start;
    text-transform: uppercase;
    @media screen and (max-width: 990px) {
        font-size: 40px;
    }
}
.nav-velo-desktop-video video,
.nav-velo-desktop-video img {
    object-fit: cover;
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.nav-acc-desktop {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    max-width: 1370px;
    margin: 0 auto;
}
.nav-acc-desktop-listing h2 {
    font-family: "GTWalsheimPro-Black";
    font-size: 46px;
    margin: -10px 0 10px 0;
    color: #131615;
    background-color: transparent;
    text-transform: uppercase;
    text-align: center;
    @media screen and (max-width: 1050px) {
        font-size: 40px;
        margin: -10px auto 0px auto;
    }
}

.nav-acc-desktop .nav-velo-desktop-video {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 70%;
    height: 75%;
    padding: 0;
    margin: 0;
    overflow: hidden;
}
.nav-acc-desktop .nav-velo-desktop-video a {
    width: 33%;
    max-height: 95%;
    padding: 0;
    margin-top: -20px;

    transition: all 0.3s ease-in-out;
}

.nav-velo-desktop-video img {
    object-fit: cover;
    overflow: hidden;
    transform: scale(1);
    transition: all 0.3s ease-in-out;
}
.nav-velo-desktop-video a:hover img {
    transform: scale(1.05);
    transition: all 0.3s ease-in-out;
    overflow: hidden;
}
.nav-acc-desktop-listing {
    display: flex;
    flex-direction: column;
    width: 30%;
    height: 80%;
    padding: 20px;
}
.nav-acc-desktop-listing hr {
    margin: 0px auto 30px;
    color: #b7b7b5;
}
.link-acc {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 65%;
}
.link-acc a,
.link-loc a {
    font-family: "GTWalsheimPro-Medium";
}
.link-acc a:hover,
.link-loc a:hover {
    text-decoration: none;
}

.link-loc {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    height: 65%;
}

.button-acc a {
    border: 2px solid black;
    border-radius: 20px;
    font-family: "GTWalsheimPro-bold";
    text-transform: uppercase;
    line-height: 80%;
    font-size: 13px;
    padding: 10px 20px;
    margin: 10px;
    background-color: white;
    transition: all 0.3s ease-in-out;
}
.button-acc a:hover {
    border: 2px solid black;
    border-radius: 20px;
    font-family: "GTWalsheimPro-bold";
    text-transform: uppercase;
    line-height: 80%;
    font-size: 13px;
    padding: 10px 20px;
    margin: 10px;
    cursor: pointer;
    background-color: #e9e7e6;
    transition: all 0.3s ease-in-out;
}
/* MOBILE NAV */
.nav-mobile {
    display: none;
    @media screen and (max-width: 1060px) {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
    }
}

.nav-icon-burger {
    font-size: 24px;
    position: absolute;
    left: 40px;
    cursor: pointer;
}

.link-account,
.link-account:hover {
    font-size: 24px;
    margin-top: 10px;
}
.link-account-mobile {
    font-size: 24px;
    position: absolute;
    right: 30px;
    top: 18px;
}
.link-account-mobile:hover {
    font-size: 24px;
    position: absolute;
    right: 30px;
    top: 18px;
}

.burger-menu {
    position: absolute;
    left: 0;
    top: 60px;
    background-color: #f0f0f0;
    min-height: 100vh;
    padding: 16px 20px;
    border-top: 1px solid #e9e7e6;
    overflow-y: scroll;
    display: flex;
    flex-direction: column;
    transition: all 0.6s ease-in-out;
    z-index: 100;
    width: 97vw;

    @media screen and (max-width: 450px) {
        padding: 16px 10px;
    }
}
.burger-translate {
    transform: translateX(-140vw);
    transition: all 0.6s ease-in-out;
}

.burger-menu h2 {
    font-family: "GTWalsheimPro-UltraBold";
    font-size: 20px;
    margin: 0;
    margin-bottom: 10px;
    color: #131615;
    background-color: transparent;
    cursor: pointer;
    text-transform: uppercase;
    text-decoration: none;
}

.burger-velo {
    border-bottom: 1px solid #b7b7b5;
    padding-top: 10px;
    height: 50px;
    overflow: hidden;
    text-transform: uppercase;
    font-family: "GTWalsheimPro-Bold";
}
.pointer {
    cursor: pointer;
}
.ms-2 {
    margin-left: 10px;
}
.burger-expand {
    height: fit-content;
}
.arrow-burger {
    position: relative;
    font-size: 26px;
    top: -45px;
    right: -91%;
}

.burgur-content-text {
    margin-left: 30px;
    font-family: GTWalsheimPro-Bold;
    font-size: 16px;
    line-height: 120%;
    margin-top: -35px;
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    font-size: 14px;
    text-transform: initial;

    @media screen and (max-width: 390px) {
        margin-left: 10px;
    }
}

.burgur-content-text a {
    margin: 10px 0;
    text-decoration: none;
}

.burgur-content-text a:hover {
    font-family: GTWalsheimPro-Bold;
    text-decoration: none;
    margin: 10px 0;
}
.burgur-content-text-velo {
    margin: 0;
    margin-bottom: 10px;
    text-transform: initial;

    margin-left: 10px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr;
    gap: 20px;
    @media screen and (max-width: 1050px) {
        width: 90%;
        margin-top: -30px;
        margin-bottom: 10px;
    }
    @media screen and (max-width: 550px) {
        display: flex;
        flex-direction: column;
        width: 90%;
    }
}
.burgur-content-text-velo a {
    margin: 5px 0;
    text-decoration: none;
}
.burgur-content-text-velo a:hover {
    margin: 5px 0;
    text-decoration: none;
    cursor: pointer;
}

.link-burger-velo {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: fit-content;
}
.link-burger-velo div {
    margin-left: 10px;
    @media screen and (max-width: 990px) {
        margin-left: 20px;
    }
}
.link-burger-velo div h3 {
    font-family: GTWalsheimPro-Bold;
    font-size: 16px;
    line-height: 120%;
    color: #131615;
    margin: 0;
    padding: 0;
    background-color: transparent;
}
.link-burger-velo div p {
    font-family: GTWalsheimPro-Regular;
    font-size: 14px;
    line-height: 110%;
    color: #696969;
    margin: 0;
    margin-top: 5px;
    padding: 0;
    background-color: transparent;
}
.img-burger-velo {
    background-color: #dfdddd;
    width: 88px;
    height: 78px;
    object-fit: contain;
}

.burger-location {
    border-bottom: 1px solid #b7b7b5;
    height: 50px;
    margin-top: -10px;
    padding-bottom: 20px;
}
.burger-location a:hover {
    text-decoration: none;
}

.menu-mobile-btn-bottom {
    background: #ffffff;
    border: 1px solid #f0f0f0;
    border-radius: 60px;
    display: flex;
    align-items: center;
    padding: 10px;
    line-height: 1.2;
    margin-top: 20px;
    margin-right: 10px;
}
.menu-mobile-btn-bottom a {
    font-family: GTWalsheimPro-Medium;
    padding: 14px 24px;
    font-size: 17px;
    line-height: 120%;
    flex: 1;
    color: #131615;
    text-align: center;
}
.menu-mobile-btn-bottom a:hover {
    text-decoration: none;
}
.link-border-left {
    border-right: 1px solid #b7b7b562;
}

.dateSelected {
    margin: auto;
    text-align: center;
}

#my-repairs,
#my-velos {
    margin: auto;
}
.graphVeloRow,
.GraphCommandes {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: flex-start;
}

/* LOGIN */
.gradient-background__wrapper {
    background: #141414;
    position: fixed;
    width: 100%;
    height: 100%;
    overflow: hidden;
    z-index: -1;
}

.gradient-background__wrapper {
    background: white;
    position: fixed;
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.gradient-background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    -webkit-filter: blur(250px);
    filter: blur(250px);
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
}

.gradient-background__shape {
    -webkit-animation-duration: 20s;
    animation-duration: 20s;
    position: absolute;
    border-radius: 100%;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-timing-function: cubic-bezier(0.1, 0, 0.9, 1);
    animation-timing-function: cubic-bezier(0.1, 0, 0.9, 1);
}

.gradient-background__shape--1 {
    bottom: 0;
    left: 0;
    width: 700px;
    height: 700px;
    background: #1d3c34;

    -webkit-transform: translate(-30%, 40%);
    transform: translate(-30%, 40%);
    -webkit-animation-name: gradientShapeAnimation1;
    animation-name: gradientShapeAnimation1;
}

.gradient-background__shape--2 {
    top: 0;
    right: 0;
    width: 600px;
    height: 600px;
    background: #eef386;
    -webkit-transform: translate(20%, -40%);
    transform: translate(20%, -40%);
    -webkit-animation-name: gradientShapeAnimation2;
    animation-name: gradientShapeAnimation2;
}

@-webkit-keyframes gradientShapeAnimation1 {
    0% {
        -webkit-transform: translate(-30%, 40%) rotate(-20deg);
        transform: translate(-30%, 40%) rotate(-20deg);
    }

    25% {
        -webkit-transform: translate(0%, 20%) skew(-15deg, -15deg) rotate(80deg);
        transform: translate(0%, 20%) skew(-15deg, -15deg) rotate(80deg);
    }

    50% {
        -webkit-transform: translate(30%, -10%) rotate(180deg);
        transform: translate(30%, -10%) rotate(180deg);
    }

    75% {
        -webkit-transform: translate(-30%, 40%) skew(15deg, 15deg)
            rotate(240deg);
        transform: translate(-30%, 40%) skew(15deg, 15deg) rotate(240deg);
    }

    100% {
        -webkit-transform: translate(-30%, 40%) rotate(-20deg);
        transform: translate(-30%, 40%) rotate(-20deg);
    }
}

@keyframes gradientShapeAnimation1 {
    0% {
        -webkit-transform: translate(-30%, 40%) rotate(-20deg);
        transform: translate(-30%, 40%) rotate(-20deg);
    }

    25% {
        -webkit-transform: translate(0%, 20%) skew(-15deg, -15deg) rotate(80deg);
        transform: translate(0%, 20%) skew(-15deg, -15deg) rotate(80deg);
    }

    50% {
        -webkit-transform: translate(30%, -10%) rotate(180deg);
        transform: translate(30%, -10%) rotate(180deg);
    }

    75% {
        -webkit-transform: translate(-30%, 40%) skew(15deg, 15deg)
            rotate(240deg);
        transform: translate(-30%, 40%) skew(15deg, 15deg) rotate(240deg);
    }

    100% {
        -webkit-transform: translate(-30%, 40%) rotate(-20deg);
        transform: translate(-30%, 40%) rotate(-20deg);
    }
}

@-webkit-keyframes gradientShapeAnimation2 {
    0% {
        -webkit-transform: translate(20%, -40%) rotate(-20deg);
        transform: translate(20%, -40%) rotate(-20deg);
    }

    20% {
        -webkit-transform: translate(0%, 0%) skew(-15deg, -15deg) rotate(80deg);
        transform: translate(0%, 0%) skew(-15deg, -15deg) rotate(80deg);
    }

    40% {
        -webkit-transform: translate(-40%, 50%) rotate(180deg);
        transform: translate(-40%, 50%) rotate(180deg);
    }

    60% {
        -webkit-transform: translate(-20%, -20%) skew(15deg, 15deg)
            rotate(80deg);
        transform: translate(-20%, -20%) skew(15deg, 15deg) rotate(80deg);
    }

    80% {
        -webkit-transform: translate(10%, -30%) rotate(180deg);
        transform: translate(10%, -30%) rotate(180deg);
    }

    100% {
        -webkit-transform: translate(20%, -40%) rotate(340deg);
        transform: translate(20%, -40%) rotate(340deg);
    }
}

@keyframes gradientShapeAnimation2 {
    0% {
        -webkit-transform: translate(20%, -40%) rotate(-20deg);
        transform: translate(20%, -40%) rotate(-20deg);
    }

    20% {
        -webkit-transform: translate(0%, 0%) skew(-15deg, -15deg) rotate(80deg);
        transform: translate(0%, 0%) skew(-15deg, -15deg) rotate(80deg);
    }

    40% {
        -webkit-transform: translate(-40%, 50%) rotate(180deg);
        transform: translate(-40%, 50%) rotate(180deg);
    }

    60% {
        -webkit-transform: translate(-20%, -20%) skew(15deg, 15deg)
            rotate(80deg);
        transform: translate(-20%, -20%) skew(15deg, 15deg) rotate(80deg);
    }

    80% {
        -webkit-transform: translate(10%, -30%) rotate(180deg);
        transform: translate(10%, -30%) rotate(180deg);
    }

    100% {
        -webkit-transform: translate(20%, -40%) rotate(340deg);
        transform: translate(20%, -40%) rotate(340deg);
    }
}

.gradient-background__noise {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-size: 100px;
}

/* ERP*/
.bg-grey-nav {
    background-color: #ebebeb;
}
.nav-erp {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    text-align: start;
    padding: 5px;
    width: 200px;
}
.nav-erp small {
    color: #1d3c34;
}
.nav-erp a {
    height: 20px;
    background-color: initial;
    transition: all 300ms ease-in-out;
    font-size: 11px;
    text-decoration: none;
    margin: 0;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding: 2px 3px;
    width: fit-content;
    border-radius: 10px;
    font-family: GTWalsheimPro-Medium;
}
.nav-erp a svg {
    margin-right: 10px;
}

.nav-erp a:hover {
    height: 30px;
    font-size: 15px;
    background-color: white;
    transition: all 300ms ease-in-out;
    cursor: pointer;
    text-decoration: none;
    margin: 0;
    padding: 2px 5px;
    width: fit-content;
    border-radius: 10px;
    font-family: GTWalsheimPro-Medium;
}

.nav-erp-stats {
    display: flex;
    flex-direction: column;
    padding: 5px;
    height: fit-content;
}
.nav-erp-stats a {
    background-color: initial;
    transition: all 300ms ease-in-out;
    font-size: 20px;
    text-decoration: none;
    margin: 10px auto;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding: 2px 5px;
    width: fit-content;
    border-radius: 10px;
    font-family: GTWalsheimPro-Medium;
    color: #1d3c34;
}
.nav-erp-stats a svg {
    margin-right: 10px;
}

.nav-erp-stats a:hover {
    color: #1d3c34;
    font-size: 20px;
    text-decoration: none;
    margin: 10px auto;
    background-color: #eef386;
    transition: all 300ms ease-in-out;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    cursor: pointer;
    text-decoration: none;
    padding: 2px 5px;
    width: fit-content;
    border-radius: 10px;
    font-family: GTWalsheimPro-Medium;
}

.date-picker-erp {
    position: fixed;
    top: 15px;
    left: 10vw;
    z-index: 999999;
}

.text-transparent {
    color: transparent;
}

.bg-yellow-stats {
    background-color: #eef386;
    color: black;
    padding: 8px;
    border-radius: 10px;
}
.my-5 {
    margin: 20px;
}
