.react-calendar {
    width: fit-content;
    max-width: 100%;
    background: white;
    border: 1px solid #1d3c34;
    font-family: Arial, Helvetica, sans-serif;
    line-height: 1em;
    margin: auto;
}
.react-calendar--doubleView {
    width: 700px;
}
.react-calendar--doubleView .react-calendar__viewContainer {
    display: flex;
    margin: -0.5em;
}
.react-calendar--doubleView .react-calendar__viewContainer > * {
    width: 50%;
    margin: 0em;
}
.react-calendar,
.react-calendar *,
.react-calendar *:before,
.react-calendar *:after {
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}
.react-calendar button {
    margin: 0;
    border: 0;
    outline: none;
}
.react-calendar button:enabled:hover {
    cursor: pointer;
}
.react-calendar__navigation {
    display: flex;
    height: 44px;
    margin-bottom: 1em;
}
.react-calendar__navigation button {
    min-width: 30px;
    background: none;
}
.react-calendar__navigation button:disabled {
    background-color: #f0f0f0;
}
.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus {
    background-color: #e6e6e6;
}
.react-calendar__month-view__weekdays {
    text-align: center;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 0.75em;
}
.react-calendar__month-view__weekdays__weekday {
    padding: 0.2em;
    background-color: #1d3c34;
    color: white;
    text-align: center;
}
.react-calendar__month-view__weekNumbers .react-calendar__tile {
    font-size: 1em;
    font-weight: bold;
    text-align: center;
}
.react-calendar__month-view__weekNumbers {
    background-color: blue;
    height: 500%;
    display: block;
}
.react-calendar__year-view .react-calendar__tile,
.react-calendar__decade-view .react-calendar__tile,
.react-calendar__century-view .react-calendar__tile {
    padding: 1em 1em;
    text-align: center;
}
.react-calendar__tile {
    max-width: 100%;
    padding: 10px 6.6667px;
    background: none;
    text-align: center;
    line-height: 16px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: space-around;
}
.react-calendar__tile:disabled {
    background-color: #f0f0f0;
}

.react-calendar__tile--now {
    background: #808e79;
    color: white;
}

.react-calendar__tile--hasActive {
    background: #76baff;
}
.react-calendar__tile--hasActive:enabled:hover,
.react-calendar__tile--hasActive:enabled:focus {
    background: #a9d4ff;
}
.react-calendar__tile--active {
    color: #1d3c34;
    background: rgba(255, 166, 0, 0.285);
}
.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
}
.react-calendar--selectRange .react-calendar__tile--hover {
    background-color: #e6e6e6;
}

.formData {
    display: flex;
    flex-direction: column;
    & input {
        margin: 15px auto;
    }
    & label {
        margin: auto;
    }
}

.formData[data-error]::after {
    content: attr(data-error);
    color: #6e101a;
    display: block;
    margin-top: 7px;
    margin-bottom: 7px;
    text-align: center;
    opacity: 0;
    transition: 0.3s;
}
.formData[data-error-visible="true"]::after {
    opacity: 1;
}
.formData[data-error-visible="true"] .text-control {
    border: 2px solid #e54858;
}
@keyframes animationTranslateError {
    0% {
        transform: translateX(0px);
    }
    25% {
        transform: translateX(10px);
    }
    75% {
        transform: translateX(-10px);
    }
    100% {
        transform: translateX(0px);
    }
}

.bg-error {
    border: 1px rgb(163, 1, 18) solid;
    background-color: rgba(163, 1, 18, 0.8);
    color: white;
    animation: 150ms ease-in-out 4 animationTranslateError;
}
.bg-error::placeholder {
    color: white;
}
.bg-checked {
    background-color: rgba(255, 166, 0, 0.285);
    color: green;
    transition: all ease-in-out 300ms;
}

input[data-error]::after {
    content: attr(data-error);
    color: red;
}

.react-calendar__tile--rangeStart {
    position: relative;
    background-color: white;
    color: #1d3c34;
    text-align: center;
    z-index: 0;
}
.react-calendar__tile--rangeStart::after {
    content: "";
    background-color: rgba(255, 166, 0, 0.285);
    z-index: 1;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 200%;
    height: 200%;
}
.react-calendar__tile--rangeBothEnds {
    position: relative;
    background-color: white;
    color: #1d3c34;
    text-align: center;
    z-index: 0;
}
.react-calendar__tile--rangeBothEnds::after {
    content: "";
    background-color: rgba(255, 166, 0, 0.285);
    z-index: 1;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 200%;
    height: 200%;
}
.react-calendar__tile--rangeEnd {
    position: relative;
    background-color: white;
    color: #1d3c34;
    text-align: center;
    z-index: 0;
}
.react-calendar__tile--rangeEnd::after {
    content: "";
    background-color: rgba(255, 166, 0, 0.285);
    z-index: 1;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 200%;
    height: 200%;
}
.react-calendar__month-view__days button {
    margin: auto;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}
.react-calendar__tile .react-calendar__month-view__day_day {
    background-color: #a9d4ff;
}
