.inventory {
    margin-top: 20px;
    display: flex;
    flex-direction: row;

    justify-content: space-around;
    align-items: center;
}
.div_store {
    margin: auto;
    padding: 10px;
}
.mx-auto {
    display: flex;
    flex-direction: row;
    justify-content: center;
}
table {
    border-collapse: collapse;
    border: 2px solid rgb(200, 200, 200);
    letter-spacing: 1px;
    font-size: 0.8rem;
    margin: 20px auto 50px auto;
    overflow-y: scroll;
    width: 95%;
}

td,
th {
    border: 1px solid rgb(190, 190, 190);
    padding: 5px 2px;
    text-align: center;
}

th {
    background-color: rgb(235, 235, 235);
}

tr:nth-child(even) {
    background-color: rgb(230, 227, 227);
}

tr:nth-child(odd) {
    background-color: rgb(245, 245, 245);
}

tr:hover {
    background-color: #eef3865f;
}
td a {
    color: #d76932;
}

#td-header td,
#td-footer td {
    background: #1d3c34;
    color: white;
}
.td-header td,
.td-footer td {
    background: #1d3c34;
    color: white;
}
.table-responsive {
    overflow: auto;
    max-height: 68vh;
}
caption {
    padding: 10px;
}

.d-flex-row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: center;
}

.text-center {
    text-align: center;
}

.button {
    background-color: #808e79;
    padding: 15px 10px;
    text-align: center;
    margin: 15px auto;
    border-radius: 5px;
    color: white;
    width: fit-content;
    max-width: 150px;
    height: 30px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}
.button:hover,
.button-selected {
    background-color: #eef386;
    color: #1d3c34;
    padding: 15px 10px;
    margin: 15px auto;
    text-align: center;
    border-radius: 5px;
    width: fit-content;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.f-column {
    display: flex;
    flex-direction: column;
    width: fit-content;
    justify-content: center;
    align-items: center;
    margin: auto;
}

.sum {
    width: fit-content;
    margin: 20px auto;
    text-align: center;
    border: #eef386 2px solid;
    padding: 5px;
    border-radius: 5px;
}
.pie {
    width: fit-content;
    height: fit-content;
    margin: -60px 0;
    text-align: center;
}

.pie-center {
    width: fit-content;
    margin: auto;
}

.ms-1 {
    padding: 20px;
}

.bg-error {
    background-color: rgb(165, 17, 17);
    color: white;
}

.mb-4 {
    margin-bottom: 40px;
}

.mb-4:hover {
    margin-bottom: 40px;
}

.my-2 {
    margin: 20px auto;
}

.table_pdv {
    background-color: #808e7960;
    padding: 10px;
    margin: 20px auto;
    width: fit-content;
    border-radius: 5px;
}

.d-row-space {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    font-size: 18px;
}
.text-shopify {
    color: rgb(112, 177, 26);
}

.btn-orderpro {
    padding: 5px;
    background-color: rgb(54, 25, 132);
    color: white;
    margin: 10px auto;
    border: none;
    border-radius: 5px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}
.btn-orderpro:hover {
    cursor: pointer;
}
.btn-orderpro svg {
    font-size: 23px;
    margin: 0 5px;
}

.btn-export-csv {
    padding: 5px;
    background-color: #eef386;
    color: #1d3c34;
    margin: 10px auto;
    border: none;
    border-radius: 5px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}
