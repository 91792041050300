.velo_product {
   
    border: 2px solid #1d3c34;
    color: black;
    font-family: "GTWalsheimPro-Black";
    text-transform: uppercase;
    padding: 10px;
    border-radius: 10px;
    transition: all ease-in-out 450ms;
    width: fit-content;
    margin: 10px auto;
    display: flex;
    flex-direction: row;
    text-wrap: wrap;
    @media screen and (max-width: 550px) {
        width: 40%;
        margin: 10px auto;
        padding: 5px;
        font-size: 14px;
    }
    @media screen and (max-width: 450px) {
        width: 35%;
        margin: 10px auto;
        padding: 5px;
    }
}
.velo_product span {
    margin: auto;
}

.velo_product_modele {
    background-color: #e4e4e3;
    padding: 10px;
    border-radius: 10px;
    border: 2px solid #1d3c34;
    transition: all ease-in-out 450ms;
    width: 50%;
    margin: 15px auto;
    font-family: "GTWalsheimPro-Black";
    text-transform: uppercase;
    color: black;
    @media screen and (max-width: 990px) {
        width: 60%;
    }
    @media screen and (max-width: 750px) {
        width: 90%;
    }
}
.velo_product_modele:hover,
.selected {
    background-color: #cecece;
    color: black;
    cursor: pointer;
    transition: all ease-in-out 450ms;
    border: 2px solid #1d3c34;
}

.velo_product:hover {
    @extend .velo_product;
    background-color: #cecece;
    color: black;
    cursor: pointer;
    transition: all ease-in-out 450ms;
    border: 2px solid #1d3c34;
}

.velo_sku_select {
    @extend .velo_product;
    background-color: #cecece;
    padding: 15px;
    color: black;
    cursor: pointer;
    transition: all ease-in-out 450ms;
    border: 2px solid #1d3c34;
    @media screen and (max-width: 550px) {
        width: 90%;
        margin: auto;
    }
}
