.parrainage {
    padding: 5px 0;
}
.banner-parrainage {
    background-image: url(../Assets/B224_C018_0627ZU.0001262.jpg);
    background-position: 50% 45%;
    background-size: cover;
    height: 360px;
    margin: -15px -15px 20px -15px;
}

.parrainage h4 {
    background-color: transparent;
    color: black;
    font-family: "GTWalsheimPro-Black";
    text-transform: uppercase;
    font-size: calc(34px + (40 - 34) * ((100vw - 425px) / (1440 - 425)));
    font-weight: 800;
    text-align: start;
    width: 50%;
    margin: 0;
    line-height: 100%;
    @media screen and (max-width: 920px) {
        font-size: 36px;
        width: 90%;
    }
}

.form-control {
    width: 300px;
    padding: 10px;
    margin: 10px 0;
    border: 1px solid #b7b7b5;
    border-radius: 10px;
    font-size: 16px;
    line-height: 130%;
    color: #363635;
    @media screen and (max-width: 550px) {
        padding: 10px 5px;
    }
}

.mx-0 {
    margin-left: 0;
    margin-right: 0;
}

.text-error {
    color: red;
    font-size: 10px;
    margin-top: 5px;
}

.my-3 {
    margin: 30px 0;
}

.w-75 {
    width: 75%;
}

.mt-40 {
    margin-top: 40px;
}
