fieldset {
    background-color: #d9d9d6;
    padding: 10px;
    margin: 15px auto;
    border-radius: 0px;
    width: 90%;
    border: none;

    @media screen and (max-width: 650px) {
        width: 90%;
        margin: 10px;
    }
}

fieldset legend {
    background-color: #1d3c34;
    color: white;
    padding: 5px;
    border-radius: 5px;
}

fieldset input,
label {
    margin: 5px 0 5px 5px;
}

.statut_garantie {
    display: flex;
    flex-direction: row;
    max-width: 100%;
}
.statut_garantie div {
    font-size: 18px;
    font-family: "GTWalsheimPro-Regular";
    text-transform: initial;
}

.etat_small {
    padding: 30px auto;
    display: block;
    color: black;
    font-family: "GTWalsheimPro-Black";
    text-transform: uppercase;
    font-size: 26px;
    margin: 40px 0 15px 0;
}
.etat_small hr {
    width: 40%;
    margin: 30px auto;
}

.files_uploaded {
    display: flex;
    flex-direction: column;
}
.files_uploaded span {
    margin-left: 20px;
}

.w-form input {
    width: 90%;
    height: 38px;
    border-radius: 5px;
    border: solid 1px #1d3c34;
    margin: 10px;
    @media screen and (max-width: 920px) {
        width: 70%;
        margin: 10px;
    }
    @media screen and (max-width: 550px) {
        width: 90%;
        margin: 10px;
    }
}

.w-95 input {
    width: 95%;
    height: 38px;
    border-radius: 5px;
    border: solid 1px #1d3c34;
    margin: 10px;
}

form input[type="file"] {
    border: none;
}

textarea {
    width: 90%;
    border-radius: 5px;
    border: solid 1px #1d3c34;
    margin: 10px;
    @media screen and (max-width: 920px) {
        width: 70%;
        margin: 10px;
    }
    @media screen and (max-width: 550px) {
        width: 90%;
        margin: 10px;
    }
}
.form-list {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

#inputDateBuy {
    width: 130px;
    margin-left: 20px;
}

.button_send {
    background-color: #1d3c34;
    color: white;
    padding: 10px;
    border-radius: 5px;
    width: fit-content;
    cursor: pointer;
}
.button_send-disabled {
    padding: 10px;
    border-radius: 5px;
    width: fit-content;
    color: white;
    background-color: gray;
    cursor: not-allowed;
    margin: auto;
}
.mx-auto {
    margin: 20px auto 60px auto;
    text-align: center;
}

.send_success {
    margin: 30px auto 60px auto;
}

.loaderCycle {
    width: 106px;
    height: 56px;
    display: block;
    margin: 30px auto;
    background-image: linear-gradient(#1d3c34 50px, transparent 0),
        linear-gradient(#1d3c34 50px, transparent 0),
        linear-gradient(#1d3c34 50px, transparent 0),
        linear-gradient(#1d3c34 50px, transparent 0),
        radial-gradient(circle 14px, #1d3c34 100%, transparent 0);
    background-size: 48px 15px, 15px 35px, 15px 35px, 25px 15px, 28px 28px;
    background-position: 25px 5px, 58px 20px, 25px 17px, 2px 37px, 76px 0px;
    background-repeat: no-repeat;
    position: relative;
    transform: rotate(-45deg);
    box-sizing: border-box;
    margin: 60px auto;
}
.loaderCycle::after,
.loaderCycle::before {
    content: "";
    position: absolute;
    width: 56px;
    height: 56px;
    border: 4px solid #1d3c34;
    border-radius: 50%;
    left: -45px;
    top: -10px;
    background-repeat: no-repeat;
    background-image: linear-gradient(#1d3c34 64px, transparent 0),
        linear-gradient(#1d3c34 66px, transparent 0),
        radial-gradient(circle 4px, white 100%, transparent 0);
    background-size: 40px 1px, 1px 40px, 8px 8px;
    background-position: center center;
    box-sizing: border-box;
    animation: rotation 0.3s linear infinite;
}
.loaderCycle::before {
    left: 25px;
    top: 60px;
}

@keyframes rotation {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.coordonnees {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    flex-wrap: wrap;
}

.coordonnees .form-list {
    width: 45%;
    @media screen and (max-width: 550px) {
        width: 90%;
        margin: 10px;
    }
}
.coordonnees .form-list small {
    display: block;
    height: 40px;
    text-align: center;
    color: #1d3c34;
}

.form-control[data-error]::after {
    content: attr(data-error);
    font-size: 20px;
    color: #6e101a;
    display: block;
    margin-top: 7px;
    margin-bottom: 7px;
    text-align: left;
    height: 20px;
    opacity: 0;
    transition: 0.3s;
}
.form-control[data-error-visible="true"]::after {
    opacity: 1;
}
.form-control[data-error-visible="true"] .text-control {
    border: 2px solid #e54858;
}
@keyframes animationTranslateError {
    0% {
        transform: translateX(0px);
    }
    25% {
        transform: translateX(10px);
    }
    75% {
        transform: translateX(-10px);
    }
    100% {
        transform: translateX(0px);
    }
}

.bg-error {
    border: 1px rgb(163, 1, 18) solid;
    background-color: rgba(163, 1, 18, 0.8);
    color: white;
    animation: 150ms ease-in-out 4 animationTranslateError;
}
.bg-error::placeholder {
    color: white;
}
.form-checked {
    background-color: rgb(233, 245, 216);
    color: green;
    transition: all ease-in-out 300ms;
}

input[data-error]::after {
    content: attr(data-error);
    font-size: 1em;
    color: red;
}

.mt-4 {
    margin-top: 40px;
}

.confirmForm {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin: 20px 0;
}

.confirmForm input {
    width: 20px;
}

.bg-grey {
    background-color: #6a6767;
    color: white;
}

.bg-grey:hover {
    cursor: hidden;
}
