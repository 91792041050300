.velo_select {
    background-color: #d9d9d6;
    padding: 20px;
    margin: 15px auto;
    border-radius: 5px;
    width: fit-content;
    min-width: 60%;
}
.velo_select-img {
    width: 90%;
    max-width: 400px;
    border-radius: 30px;
    transition: all 0.3s ease-in-out;
    @media screen and (max-width: 800px) {
        max-width: 300px;
    }
    @media screen and (max-width: 600px) {
        max-width: 150px;
    }
}

.statut {
    padding: 2px 8px;
    margin-left: 3px;
    font-size: 12px;
    color: #1d3c34;
    border-radius: 5px;
    width: fit-content;
}
.statut-expired {
    background-color: rgb(247, 184, 184);
    color: rgb(247, 184, 184);
}

.statut-actived {
    background-color: rgb(192, 247, 184);
    color: rgb(192, 247, 184);
}

.statut-pending {
    background-color: rgb(244, 247, 184);
    color: rgb(247, 223, 184);
}

.statut-show {
    color: #1d3c34;
}

.statut-text_size {
    font-size: 16px;
}

.text-start {
    text-align: start;
}

.min-h-full {
    min-height: 100vh;
}
