.banner-reprise {
    background-image: url(../Assets/IMGP8826.jpg);
    background-position: 50% 50%;
    background-size: cover;
    height: 400px;
    margin: -62px -15px 20px -15px;
}

.banner-reprise::after {
    content: "";
    background: linear-gradient(
        180deg,
        rgba(0, 0, 0, 0) 40%,
        rgba(0, 0, 0, 0.4) 100%
    );
    position: absolute;
    top: 0;
    left: 0;
    height: 415px;
    width: 100%;
    z-index: 1;
}
