.search-client h2 {
    color: #eef386;
}
.icon-presta {
    background-color: white;
    color: #1d3c34;
    width: 20px;
    height: 20px;
    margin: 0 5px;
}

.loader-container {
    margin: 20px;
    width: fit-content;
    height: 100%;
}

.loader_k {
    width: 48px;
    height: 48px;
    display: inline-block;
    position: relative;
    margin: auto;
    transform: scale(1.5);
}
.loader_k::after,
.loader_k::before {
    content: "";
    box-sizing: border-box;
    width: 48px;
    height: 48px;
    border-radius: 50%;
    border: 2px solid #1d3c34;
    position: absolute;
    left: 0;
    top: 0;
    animation: animloader_k 1.5s linear infinite;
}
.loader_k::before {
    animation-delay: 0s;
}
.loader_k::after {
    animation-delay: 0.75s;
}

@keyframes animloader_k {
    0% {
        transform: scale(0);
        opacity: 1;
    }
    100% {
        transform: scale(1);
        opacity: 0;
    }
}

.title-result {
    background-color: #1d3c34;
    padding: 10px;
    margin: 20px 0;
    text-align: center;
    border-radius: 5px;
    color: white;
    width: fit-content;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.result {
    background-color: #808e79;
    padding: 20px;
    margin: 15px auto;
    text-align: center;
    border-radius: 5px;
    color: white;
    width: fit-content;
    display: flex;
    flex-direction: column;
}

.result-presta {
    background-color: #808e79;
    padding: 20px;
    margin: 15px auto;
    text-align: center;
    border-radius: 5px;
    color: white;
    width: fit-content;
    display: flex;
    flex-direction: column;
}
.result-presta:hover,
.result-presta-selected {
    background-color: #eef386;
    color: #1d3c34;
    padding: 20px;
    margin: 15px auto;
    text-align: center;
    border-radius: 5px;
    width: fit-content;
    display: flex;
    flex-direction: column;
    cursor: pointer;
}

.orders {
    background-color: #9ba496;
    padding: 20px;
    margin: 15px auto;
    text-align: center;
    border-radius: 5px;
    color: white;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
}

.orders h3 {
    margin-top: 5px;
    transition: all ease-in-out 300ms;
}
.orders a,
.result a {
    text-decoration: none;
    color: white;
    transition: all ease-in-out 300ms;
}
.button-action {
    padding: 10px;
    margin: 15px auto;
    text-align: center;
    border-radius: 5px;
    background-color: #eef386;
    color: #1d3c34;
    transition: all ease-in-out 300ms;
}
.button-action:hover {
    cursor: pointer;
}

.orders h3:hover a,
.result h3:hover a {
    color: #1d3c34;
    transition: all ease-in-out 300ms;
}
.order-details {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    flex-wrap: wrap;
}
.order-details-div {
    background-color: #d9d9d6;
    padding: 10px;
    border-radius: 5px;
    color: #1d3c34;
    text-align: start;
    width: 45%;
}
.order-details-div div {
    margin: 8px 0;
}
.bg-dark-green {
    background-color: #1d3c34;
    padding: 5px;
    border-radius: 5px;
}

.card_user {
    background-color: #808e79;
    padding: 10px;
    border-radius: 5px;
    margin: 20px auto;
    width: 75%;
    color: white;
}
.gestion_user {
    background-color: #d9d9d6;
    padding: 10px;
    border-radius: 5px;
    margin: 20px auto;
    width: 60%;
    text-align: center;
}

.disabled {
    background-color: #d9d9d6;
}

.list_home {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}

.list_home button {
    margin: 20px auto;
}

.list_home a {
    text-decoration: none;
}
.search-bdd-input {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
}
