footer {
    background-color: #1d3c34;
    display: flex;
    justify-content: center;
    width: 100%;
    border-top: 3px solid white;
    color: white;

    & img {
        width: 20px;
    }
    & a {
        text-decoration: none;
        transition: all ease-in-out 400ms;
        color: white;
    }
    & a:hover {
        color: rgb(246, 223, 193);
        transition: all ease-in-out 400ms;
    }
    @media screen and (max-width: 450px) {
        font-size: 9px;
    }
}
.litiboost {
    margin-top: 1px;
    margin-left: 10px;
}
