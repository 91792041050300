.form_login {
    display: flex;
    flex-direction: column;
    width: 90%;
    margin: 20px auto;
}
.h1-login {
    background-color: initial;
    color: black;
    font-family: "GTWalsheimPro-UltraBold";
    text-align: start;
    margin: 0 0 30px 10px;
}
.h2-login {
    background-color: initial;
    color: black;
    text-align: start;
    margin: 0;
}
.h3-login {
    background-color: initial;
    color: grey;
    text-align: start;
    margin: -10px 0 0 0;
    font-size: 14px;
}
.form_login-button {
    background-color: #1d3c34;
    padding: 15px 20px;
    margin: 20px auto;
    text-align: center;
    border-radius: 5px;
    color: white;
    width: fit-content;
    border: none;
    transition: all 300ms ease-in-out;
}
.form_login-button:hover {
    background-color: #eef386;
    padding: 15px 20px;
    border: none;
    border-radius: 5px;
    color: black;
    width: fit-content;
    transition: all 300ms ease-in-out;
    cursor: pointer;
}

.form_login input {
    width: 90%;
    height: 38px;
    border-radius: 5px;
    border: solid 1px #1d3c34;
    margin: 10px;
    @media screen and (max-width: 920px) {
        width: 70%;
        margin: 10px;
    }
    @media screen and (max-width: 550px) {
        width: 90%;
        margin: 10px;
    }
}

.login {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: calc(100vh - 52px);
}
.login-content {
    z-index: 100;
    transition: all 300ms ease-in-out;
    border-radius: 5px;
    position: relative;
    padding: 20px;
}

.login .logo_velo {
    width: fit-content;
}
.login .logo_velo img {
    width: 150%;
}
