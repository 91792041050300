.detect_pieces {
    display: flex;
    flex-direction: column;
}

.w-50 {
    width: 45%;
    margin: auto;
}

.piece {
    padding: 8px;
    transition: all ease-in-out 250ms;
    word-wrap: break-word;
    font-size: 14px;
    text-align: center;
    overflow: hidden;
    border-radius: 5px;
    width: 90%;
}
.piece:hover {
    cursor: pointer;
    background-color: #eef386;
    color: black;

    transition: all ease-in-out 250ms;
}
.click-info-piece {
    color: white;
    background-color: #808e79;
    padding: 5px;
    width: fit-content;
    border-radius: 5px;
    font-size: 14px;
}
.flex-column {
    display: flex;
    flex-direction: column;
    height: 15px;
}

.piece_select {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    height: fit-content;
    max-height: 50%;
    background-color: #808e79;
    padding: 20px 20px 60px 20px;
    overflow: scroll;
    margin: auto;

    color: white;
    text-align: center;
}
.piece_select-in {
    width: 90%;
    margin: auto;
}
.piece_select-close {
    cursor: pointer;
    background-color: #eef386;
    color: black;
    transition: all ease-in-out 250ms;
    padding: 5px;
    border-radius: 5px;
    width: fit-content;
}

.mb-5 {
    margin-bottom: 50px;
}

.display_cat_sav {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: center;
    margin: auto;
}

.listing-cat {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}
.listing-cat div {
    margin: 10px;
    font-size: 18px;
    font-family: "GTWalsheimPro-Regular";
    text-transform: initial;
    @media screen and (max-width: 750px) {
        margin: 3px;
    }
}
.btn-cat-sav {
    background-color: #808e79;
    color: white;
    padding: 10px;
    border-radius: 5px;
    margin: 10px;
    width: fit-content;
    cursor: pointer;
    transition: all ease-in-out 250ms;
}

.btn-cat-sav-selected {
    background-color: #eef386;
    color: black;
    padding: 10px;
    border-radius: 5px;
    margin: 10px;
    width: fit-content;
    cursor: pointer;
    transition: all ease-in-out 250ms;
}

.icon-bin {
    color: orange;
    cursor: pointer;
    transition: all ease-in-out 250ms;
    font-size: 22px;
    margin-right: 10px;
}
.itemslines-panier {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin: 10px;
    padding: 10px;
    border-radius: 5px;
    background-color: white;
    color: black;
    transition: all ease-in-out 250ms;
}
.ps-2 {
    padding: 0 10px;
}

.d-row-align {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}
.listing_sav {
    display: flex;
    flex-direction: column;
}
.pieceSav {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: fit-content;
    min-height: 25vh;
    padding: 10px;
    margin: 10px auto;
    border-radius: 10px;
    background-color: white;
    color: black;
    border: solid 2px #1d3c34;
    transition: all ease-in-out 250ms;
    width: 45vw;
    @media screen and (max-width: 1440px) {
        width: 55vw;
    }
    @media screen and (max-width: 990px) {
        width: 75vw;
    }
    @media screen and (max-width: 750px) {
        width: 85vw;
    }
    @media screen and (max-width: 550px) {
        width: 87vw;
    }
}
.listing-variant-piece {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    text-align: center;
    margin: auto;
    width: 100%;
}
.pieceSav hr {
    width: 40%;
    margin: 20px auto;
}
.pieceSav h4 {
    font-family: "GTWalsheimPro-Black";
    text-transform: uppercase;
}

.btn-panier {
    margin: 20px auto 10px auto;
    padding: 12px 24px;
    border: none;
    color: white;
    background: #808e79;
    transition: all 0.3s ease-in-out;
    text-decoration: none;
    width: 170px;
    letter-spacing: 0.04em;
    border-radius: 50px;
    cursor: pointer;
    font-weight: 500;
    font-size: 16px;
    line-height: 130%;
    font-family: "GTWalsheimPro-Regular", sans-serif;
    letter-spacing: 0.05em;
    text-align: center;
    text-wrap: nowrap;
    @media screen and (max-width: 1100px) {
        padding: 12px;
    }
    @media screen and (max-width: 900px) {
        padding: 12px;
        font-size: 14px;
        line-height: 100%;
        width: 150px;
    }
    @media screen and (max-width: 550px) {
        padding: 12px 6px;
        font-size: 14px;
        line-height: 100%;
        width: 130px;
    }
}
