.fixed-add {
    position: fixed;
    bottom: 40px;
    right: 50px;
    z-index: 2;
}
::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 7px;
}
::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(0, 0, 0, 0.5);
    -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}
#formModal .transparent {
    background-color: #1d3c34;
    opacity: 1;
    width: 80vw;
    height: 68vh;
    z-index: 401;
    display: flex;
    flex-direction: column;
    transition: all 1s ease-in-out;
    padding: 10px;
    position: relative;
    left: -90vw;
    overflow: hidden;
    @media screen and (max-width: 550px) {
        overflow-x: scroll;
        height: min-content;
        width: 90vw;
        left: -96vw;
    }
}
.add {
    background-color: #808e79;
    padding: 10px;
    border-radius: 5px;
    width: fit-content;
    margin: 10px auto;
    cursor: pointer;
    color: white;
}

.fixed-save {
    background-color: #1d3c34;
    color: white;
    border: solid 2px #1d3c34;
    padding: 10px;
    border-radius: 5px;
    width: auto;
    max-width: 50%;
    margin: 10px auto;
    cursor: pointer;
    text-align: center;
    transition: all 0.6s ease-in-out;
}
.fixed-save:hover {
    background-color: #eef386;
    transition: all 0.6s ease-in-out;
    color: black;
}
.addForm {
    background-color: #808e79;
    border-radius: 5px;
    margin: 20px auto;
    color: white;
    height: fit-content;
    padding: 10px;
    max-width: 700px;
}
.edit {
    font-size: 24px;
    cursor: pointer;
}

.modalForm {
    width: calc(100% - 620px);
    @media screen and (max-width: 650px) {
        width: 95vw;
    }
}
.form-group {
    width: fit-content;
}

.form-group-map {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}
.form-group-map label {
    padding-left: 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}
.form-group-map input {
    width: 250px;
    margin-right: 20px;
}

.categorie {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 80%;
    margin: auto;
}
.fr-checkbox-group {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.fr-checkbox-group input[type="checkbox"] {
    width: 15px;
    margin: 5px;
}
.filter-categorie {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    text-align: start;
    width: fit-content;
}
.mouseover {
    cursor: pointer;
}
.text-white {
    color: white;
}
.d-row-wrap .addForm {
    z-index: 1;
    width: 45vw;
}
.d-row-wrap .modal_body {
    z-index: 1;
    width: 45vw;
    margin-left: 0;
}

.choice-velo {
    overflow-x: scroll;
    height: 200px;
    background-color: #f0f0f0;
    border-radius: 10px;
    margin: 10px auto;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
}
.choice-velo div {
    color: #1d3c34;
    display: flex;
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
    text-align: center;
    cursor: pointer;
    transition: all 0.6s ease-in-out;
}
.choice-velo div:hover {
    background-color: #eef386;
    border-radius: 10px;
    transition: all 0.6s ease-in-out;
}
.choice-velo img {
    object-fit: contain;
    width: 130px;
    height: 100px;
}
